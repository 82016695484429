import React from 'react';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router } from 'react-router-dom';
import Layout from "./layout/Layout.js";
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import './App.css';



const App = () =>{
  return(
      <Router basename={process.env.PUBLIC_URL}>
          <Layout />
      </Router>

    )


}



export default App;
