import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Collapse, Container, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import handleNavbarTransparency from '../../helpers/handleNavbarTransparency';
import NavbarLanding from './NavbarLanding';
import { topNavbarBreakpoint } from '../../config';

const NavbarStandard = () => {
  const [navbarCollapsed, setNavbarCollapsed] = useState(true);

  useEffect(() => {
    window.addEventListener('scroll', handleNavbarTransparency);
    return () => window.removeEventListener('scroll', handleNavbarTransparency);
  }, []);

  return (
    <Navbar
      dark
      fixed="top"
      expand={topNavbarBreakpoint}
      className={classNames('navbar-standard navbar-theme', {
        'bg-dark': !navbarCollapsed
      })}
    >
      <Container>
        <NavbarBrand className="text-white" tag={Link} to="/">
          <img src="https://www.moxtain.com/images/logo_moxtain.png" width="150px"  alt="https://i.etsystatic.com/26375241/r/il/d2a025/3333550667/il_340x270.3333550667_67a2.jpg" className="pb-2"/>
        </NavbarBrand>
        <NavbarToggler onClick={() => setNavbarCollapsed(!navbarCollapsed)} />
        <Collapse isOpen={!navbarCollapsed} navbar className="scrollbar">
          <Nav navbar>
            <NavbarLanding setNavbarCollapsed={setNavbarCollapsed} />
          </Nav>

        </Collapse>
      </Container>
    </Navbar>
  );
};

export default NavbarStandard;
