import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import {Card, CardBody, Col, CustomInput, Label, Row } from 'reactstrap';
import PricingCard from './PricingCard';
import FaqCollapse from '../faq/FaqCollapse';
import { isIterableArray } from '../../helpers/utils';
import pricing from '../../data/pricing/pricing';
import NavbarStandard from '../navbar/NavbarStandard';
import Section from '../common/Section';

const Pricing = ({location,match}) => {
  // State
  const [isYearly, setIsYearly] = useState(true);

  return (
    <Fragment>
      <NavbarStandard location={location} match={match} />
      <Section bg="light" className="py-3 shadow-sm">
      <Card className="mb-3 pt-5 mt-5">
        <CardBody>
          <Row noGutters>
            <Col xs={12} className="mb-3">
              <Row className="justify-content-center justify-content-sm-between">
                <Col sm="auto" className="text-center">
                  <h5 className="d-inline-block">Build Annually</h5>
                  {isYearly && <span className="badge badge-soft-success badge-pill ml-2">Save 25%</span>}
                </Col>
                <Col sm="auto" className="d-flex flex-center fs--1 mt-1 mt-sm-0">
                  <Label className="mr-2 mb-0" htmlFor="customSwitch1">
                    Monthly
                  </Label>
                  <CustomInput
                    type="switch"
                    id="customSwitch1"
                    label="Yearly"
                    checked={isYearly}
                    onChange={() => setIsYearly(!isYearly)}
                  />
                </Col>
              </Row>
            </Col>

            {isIterableArray(pricing) &&
              pricing.map((pricingItem, index) => (
                <Col lg={4} className={`${index !== 1 ? 'border-lg-y' : 'border-y'}`} key={index}>
                  <PricingCard {...pricingItem} isYearly={isYearly} />
                </Col>
              ))}

            <Col xs={12} className="text-center">
              <h5 className="mt-5">Looking for personal or small team task management?</h5>
              <p className="fs-1">
                Try the <Link to="#">basic version</Link> of Falcon
              </p>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <FaqCollapse />
      </Section>
    </Fragment>
  );
};

export default Pricing;
