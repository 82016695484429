import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { DropdownItem, DropdownMenu, DropdownToggle, Dropdown } from 'reactstrap';
import team3 from '../../assets/img/team/man.svg';
import Avatar from '../common/Avatar';
// import {connect} from 'react-redux';
// import {logout} from '../../store/action/auth';

const ProfileDropdown = (props) => {
  //console.log(props)
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const logout = () => {
    localStorage.removeItem("tenantId");
    localStorage.removeItem("token");
    localStorage.removeItem("refresh");
  }
  return (
    <Dropdown
      nav
      inNavbar
      isOpen={dropdownOpen}
      toggle={toggle}
      onMouseOver={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(true);
      }}
      onMouseLeave={() => {
        let windowWidth = window.innerWidth;
        windowWidth > 992 && setDropdownOpen(false);
      }}
    >
      <DropdownToggle nav className="pr-0">
        <Avatar src={team3} />
      </DropdownToggle>
      <DropdownMenu right className="dropdown-menu-card">
        <div className="bg-white rounded-soft py-2">
          <DropdownItem className="font-weight-bold text-warning" href="#!">

          </DropdownItem>


          <DropdownItem tag={Link} to="/pages/profile">
            Profile &amp; account
          </DropdownItem>
          <DropdownItem href="#!">Feedback</DropdownItem>
          <DropdownItem divider />
          <DropdownItem tag={Link} to="/pages/settings">
            Settings
          </DropdownItem>
          <DropdownItem tag={Link} to="/signin" onClick={logout}>
            Logout
          </DropdownItem>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

// const mapStateToProps =(state)=>{
//   return {
//     auth:state.auth

//   }


// }


// export default connect(mapStateToProps,{logout})(ProfileDropdown);

export default ProfileDropdown;
