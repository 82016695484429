
import Axios from 'axios';
import {REGISTER_USER,LOGIN_USER,LOGOUT_USER, ADD_USER} from './type.js';
//import jwt_decode from "jwt-decode";





// REGISTER USER


const register = (data)=>(dispatch)=>{
	const config = {
		headers:{
			"Content-Type":"application/json"

		}

	}

	Axios.post('http://localhost:8000/testapi/schema/',data,config).then(res=>
		{
		dispatch({
			type:REGISTER_USER,
			payload:res.data
		})

	}).catch(error=>console.log(error))


}



const Login =(username,password)=>(dispatch)=>{


	const config ={
		headers:{
			'Content-Type': 'application/json',
  			'Authorization': 'JWT fefege...'
		}

	}


	const body = JSON.stringify({username,password})


	let PostData = window.location.href
	PostData =	PostData.replace("3000/login","")
	// console.log(PostData)
	Axios.post(PostData+'8000/api/token/',body,config).then(res=>
		{

			console.log("login response", res)
			dispatch({
				type:LOGIN_USER,
				payload:res.data

			})

		})



}

// Loguout the user
const logout=()=>(dispatch,getState)=>{
	const token = getState().auth.token
	dispatch({
		type:LOGOUT_USER,
		payload:token
	})


}

//adduser

const add_user =(username,password,email)=>dispatch=>{
	const config ={
		headers:{
			'Content-Type': 'application/json',

		}
	}

	const body = JSON.stringify({username,password,email})


	let PostData = window.location.href
	PostData =	PostData.replace("3000/newUser","")
	console.log("adduser",PostData)
	Axios.post(PostData+'8000/testapi/usercreate/',body, config).then(res=>
		{

			dispatch({
				type:ADD_USER,
				payload:res.data
			})

		})

}
export {register,Login,logout,add_user};






//Token setting and using it headers

// export const tokenConfig = getState=>{
// const token = getState.data.access
// const config = {
// 	headers:{
// 		"Content-Type":"application/json"

// 		}
// }

// 	if(token){

// 		config.headers['Authorization'] = "Token "+token
// 	}


// 	return config;


// }
