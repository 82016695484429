
// export const intiaState ={
// 	user:"",
// 	token:""
// }
// export const authReducer = (state=intiaState,action)=>{
// 	console.log(state)
// 	console.log("action", action)
// 	switch(action.type){
// 		case 'LOGIN_STATUS':
// 			return{...state,user:action.user,token:action.token}
// 		default:
// 			return state;
// 	}
//
//
//
// }


export const authReducer = (state, action) => {
	console.log(state)


}
