import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';

const Loader = props => (
  <Row className="flex-center py-5">
    <Col xs="auto">
      <Spinner {...props} />
    </Col>
  </Row>
);

Loader.propTypes = { ...Spinner.propTypes };

Loader.defaultProps = {
  size: 'lg',
  color: 'dark',
  width:'3rem',
  height:'3rem'

};

export default Loader;
