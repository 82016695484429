import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Accordion = ({ title,uploadPreview ,descripHead,description, open ,editDescription,setEditDescription,setImageBool,images,editMode}) => {
  const [isOpen, setIsOpen] = useState(open);
  return (
    <Card className="shadow-none border-bottom rounded-0">
      <CardHeader onClick={() => setIsOpen(!isOpen)} className="py-2 cursor-pointer">
        <FontAwesomeIcon icon="caret-right" transform={`rotate-${isOpen ? 90 : 0})`} />
        <span className="font-weight-medium text-dark text-sans-serif pl-2">{title}</span>
      </CardHeader>
      <Collapse isOpen={isOpen}>
        <CardBody className="pt-2">
          <div className="d-flex">
            <h4>{descripHead}</h4>
            <div className="ml-auto">
              {uploadPreview?(<button className="ml-auto btn btn-success mr-2" onClick={setImageBool}>Upload</button>):(null)}
              {editMode?(<button className="ml-auto btn btn-primary" onClick={setEditDescription}>Edit</button>):(null)}
            </div>
          </div>
          <div className="pl-3"  dangerouslySetInnerHTML={{__html:description}} />
          <div className="row">
            {images === undefined?(null):(images.map((item,idx)=>(
              <div className="col-3" key={idx}>
                   <img src={item.icon_url} className="img-fluid img-thumbnail w-50" />
              </div>)))}

          </div>
        </CardBody>
      </Collapse>
    </Card>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  open: PropTypes.bool
};

Accordion.defaultProps = { open: false };

export default Accordion;
