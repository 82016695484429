import React, { useContext } from 'react';
import {mainPageRoutes,PricingRoutes,solutionRoutes,Resources} from '../../homeRoutes';
import NavbarDropdownComponents from './NavbarDropdownComponents';
import AppContext from '../../context/Context';
import { breakpoints} from '../../helpers/utils';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const NavbarLanding = ({ setNavbarCollapsed, setShowBurgerMenu })=>{
	const { isCombo, isTopNav } = useContext(AppContext);
	const handleSetNavbarCollapsed = () => {
		const windowWidth = window.innerWidth;
		isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
		isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);



	}
	const  compRoutes = [mainPageRoutes]
	const  soluRoutes = [solutionRoutes]
	const resRoutes = [Resources]
	return(
		<>
		<NavbarDropdownComponents
		  title={mainPageRoutes.name}
		  items={compRoutes}
		  handleSetNavbarCollapsed={handleSetNavbarCollapsed}

		/>
		<NavItem onClick={handleSetNavbarCollapsed}>
	        <NavLink className="nav-link" to="/price">
	          {PricingRoutes.name}
	        </NavLink>
      	</NavItem>

      	<NavbarDropdownComponents
		  title={solutionRoutes.name}
		  items={soluRoutes}
		  handleSetNavbarCollapsed={handleSetNavbarCollapsed}

		/>
		<NavbarDropdownComponents
		  title={Resources.name}
		  items={resRoutes}
		  handleSetNavbarCollapsed={handleSetNavbarCollapsed}

		/>

		</>)
}

export default NavbarLanding;
