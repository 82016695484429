import React,{useState} from "react";
import {Form,FormGroup,Input,Button} from 'reactstrap';
const DomainForm = ()=>{
	const [doaminName,setDoaminName] = useState('');
	const handleSubmit = (e)=>{
		e.preventDefault()
		window.location.replace("http://"+doaminName+".localhost:3000");

	}

	return(<>
			<Form onSubmit={handleSubmit}>
				<FormGroup>

				<Input
                  placeholder= 'Site Name'
                  type='text'
                  value={doaminName}
                  onChange={({ target }) => setDoaminName(target.value)}
                />
				</FormGroup>
				<FormGroup>
            		<Button style={{backgroundColor:"#358822"}} block className="mt-3" >
             		 Log in
            		</Button>
            	</FormGroup>

			</Form>
		</>)
}

export default DomainForm;
