import { createContext } from 'react';
import { settings } from '../config';
// import {getItemFromStore} from '../helpers/utils.js';

const AppContext = createContext(settings);

export const EmailContext = createContext({ emails: [] });

export const ProductContext = createContext({ products: [] });

export const FeedContext = createContext({ feeds: [] });

export const LeadContext = createContext({leads:[]})

export const TripContext = createContext({trips:[]})

export const StaffContext = createContext({staff:[]})

export const BusinessContext = createContext({leads:[]})

export const PromoContext = createContext({promo:[]})

export  const ModalProvider = createContext( {modalType: null,modalProps: {}})

export const ChatContext = createContext();

export const KanbanContext = createContext({ KanbanColumns: [], kanbanTasks: [] });

export const AuthContext = createContext({ user: [] });

export const BookingContext=createContext([]);
export default AppContext;
