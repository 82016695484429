export const mainPageRoutes = {
	name: 'Features',
  	to: '/',
  	exact: true,
  	icon: 'chart-pie',
  	children: [
    { to: '/dashboard-alt', name: 'Mobile' },
    { to: '/feed', name: 'Feed', exact: true },
    { to: '/landing', name: 'Security' },
    { to: '/devlopment', name: 'Development' },


  ]
}

export const solutionRoutes = {
	name: 'Solution',
  	to: '/',
  	exact: true,
  	icon: 'chart-pie',
  	children: [
    { to: '/dashboard-alt', name: 'By Size' },
    { to: '/feed', name: 'Enterprise' },
    { to: '/landing', name: 'Small Business' },
    { to: '/devlopment', name: 'By Industry' },
    { to: '/feed', name: 'Life Sciences' },
    { to: '/landing', name: 'Financial Services' },
    { to: '/devlopment', name: 'Real Estate' },


  ]
}

export const PricingRoutes = {
  name: 'Pricing',
  to: '/Pricing',
  exact: true,
  icon: 'book'
};
export const Resources = {
	name: 'Resources',
	to: '/Resources',
	exact: true,
  	icon: 'chart-pie',
  	children: [
    { to: '/dashboard-alt', name: '2020 Feature Edition' },
    { to: '/feed', name: 'Documentation' },
    { to: '/landing', name: 'Training' },
    { to: '/devlopment', name: 'Videos' },
    { to: '/feed', name: 'Webinars' },
    { to: '/landing', name: 'FAQs' },
    { to: '/devlopment', name: 'Ebooks' },


  ]


}

export default [
	mainPageRoutes,
	PricingRoutes,
	solutionRoutes,
	Resources
]