import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton, Fade } from '../components/common/Toast';
import Login from "../components/auth/split/Login";
import Register from "../components/auth/split/Registration";
import Pricing from "../components/pricing/Pricing";
import DomainAuth from "../components/domainAuth/DomainAuth";


//import {Provider} from 'react-redux';
//import Store from '../store/Store.js';


import DashboardLayout from './DashboardLayout';
import loadable from '@loadable/component';

const Landing = loadable(() => import('../components/landing/Landing'));

const Layout = () =>{
	return(

					<Router fallback={<span />}>

						<Switch>
							<Route exact path="/"  component={Landing}/>
							<Route exact path="/pricing" component ={Pricing} />
							<Route exact path="/signin"  component={Login}/>
							<Route exact path="/domain"  component={DomainAuth}/>
							<Route exact path="/register"  component={Register}/>
							<Route component={DashboardLayout} />
							{/* <PrivateRoute  component={DashboardLayout} /> */}

							{/* <PrivateRoute exact path="/dashboard" component={DashboardLayout}/> */}

						</Switch>
						<ToastContainer transition={Fade} closeButton={<CloseButton />} position={toast.POSITION.BOTTOM_LEFT} />

					</Router>

		);

}

export default Layout;
