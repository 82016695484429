import React, { useContext, useEffect, useRef,useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Collapse, Nav, Navbar } from 'reactstrap';
import is from 'is_js';
import classNames from 'classnames';
import Logo from './Logo';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';
import AppContext from '../../context/Context';
import Flex from '../common/Flex';
import jwt_decode from "jwt-decode";
import routes from '../../routes';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import { getItemFromStore} from '../../helpers/utils';
import bgNavbarImg from '../../assets/img/generic/bg-navbar.png';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';

const NavbarVertical = ({ navbarStyle }) => {
  const [newRoutes,setRoutes]  = useState([]);
  const navBarRef = useRef(null);

  const {
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    isCombo,
    setShowBurgerMenu,
    setNavbarCollapsed,
  } = useContext(AppContext);


const checkRoute = ()=>{
    setRoutes(routes)

  }

  useEffect(checkRoute,[])


  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={40} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
        style={
          navbarStyle === 'vibrant' && {
            backgroundImage: `linear-gradient(-45deg, rgba(0, 160, 255, 0.86), #0048a2),url(${bgNavbarImg})`
          }
        }
      >
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={newRoutes} />
        </Nav>
        <div className="settings px-3 px-xl-0">
          {isCombo && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu} />
              </Nav>
            </div>
          )}
          <div className="navbar-vertical-divider">
            <hr className="navbar-vertical-hr my-2" />
          </div>
          <Button
            tag={'a'}
            href="https://www.moxtain.com/"
            target="_blank"
            color="primary"
            size="sm"
            block
            className="my-3 btn-purchase"
          >
            Booking
          </Button>
        </div>
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'transparent'
};

export default NavbarVertical;



// let perm = getItemFromStore('token')
// let token = jwt_decode(perm)
// if(token['claims'] !== undefined){
//    const perm1 = token['claims'].find((item)=>item === 'create_user')
//    const perm2 = token['claims'].find((item)=>item === 'create_business')
//    let permRoute ;
//
//    if(perm1 === 'create_user' && perm2 ==='create_business'){
//       permRoute =routes
//    }
//    else if(perm1 === 'create_user'){
//       let uRoute = routes.filter((items)=>items.name !== 'Business User' )
//       permRoute =uRoute;
//    }
//   setRoutes(permRoute)
//
// }
// else{
// let mRoute =routes.filter((items)=>items.name !== 'User' )
//  mRoute =mRoute.filter((items)=>items.name !== 'Business User')
// setRoutes(mRoute)
//
// }
//
