// import {Redirect} from "react-router-dom";
import {connect} from 'react-redux';
import {register} from '../../store/action/auth';
import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
// import { toast } from 'react-toastify';
import { Button, CustomInput, Form, FormGroup, Input, Label } from 'reactstrap';
import Divider from '../common/Divider';
import SocialAuthButtons from './SocialAuthButtons';
import withRedirect from '../../hoc/withRedirect';


const RegisterForm = ()=>{
  const handleSubmit = ()=>{

  }

  return(<>
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Input
              placeholder='Name'
              value=""
              type='text'
              />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder='Email'
              value=""
              type='email'
              />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder='Phone'
              value=""
              type='text'
              />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder='Business Name'
              value=""
              type='text'
              />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder='Location'
              value=""
              type='text'
              />
          </FormGroup>
          <CustomInput
            id="customCheckTerms"
            label={<Fragment> I accept the <Link to="#!">terms</Link> and <Link to="#!">privacy policy</Link></Fragment>}
            type="checkbox"
            />
            <FormGroup>
              <Button color="primary" block className="mt-3" >Register</Button>
            </FormGroup>
            <Divider className="mt-4">OR</Divider>
            <span className="font-weight-semi-bold">Already User? </span>
            <Link to="/signin">Login</Link>


        </Form>
      </>)


}





export default withRedirect(RegisterForm);


// class registerform extends React.Component{
//     state={
//     schoolName:"",
//     username:"",
//     email:"",
//     phone:"",
//     date:"",
//     password:"",
//         cpassword:"",
//         layout:"",
//         hasLabel:null,
//         isAccepted:false
//
//     }
//     static propTypes = {
//     register:PropTypes.func.isRequired,
//     url:PropTypes.string,
//     loading:PropTypes.bool
//
//
//     }
//
//     handleSubmit =(event)=>{
//     event.preventDefault()
//     let data={}
//     data['school'] = this.state.schoolName;
//     data['name'] = this.state.username;
//     data['email'] = this.state.email;
//     data['mobile'] = this.state.phone;
//     data['paid_until'] = this.state.date;
//     data['password'] = this.state.password;
//     this.props.register(data);
//
//     //Axios.post('http://localhost:8000/testapi/schema/',data).then(response=>console.log(response)).catch(error=>console.log(error))
//
//   }
//
//
//
//
//     render(){
//
//
//         return(
//             <Form onSubmit={this.handleSubmit}>
//                 <FormGroup>
//                     { <Label>schoolName</Label>}
//                     <Input
//                         placeholder={ 'schoolName' }
//                         value={this.schoolName}
//                         onChange={(e)=>{this.setState({schoolName:e.target.value}) }}
//                         type='text'
//                      />
//                 </FormGroup>
//                 <FormGroup>
//                     { <Label>username</Label>}
//                     <Input
//                         placeholder={ 'username' }
//                         value={this.username}
//                         onChange={(e)=>{this.setState({username:e.target.value}) }}
//                         type='text'
//                      />
//                 </FormGroup>
//                 <FormGroup>
//                     { <Label>Email address</Label>}
//                     <Input
//                         placeholder={ 'Email address' }
//                         value={this.email}
//                         onChange={(e)=>this.setState({email:e.target.value})}
//                         type="email"
//                     />
//                 </FormGroup>
//                 <FormGroup>
//                     { <Label>phone</Label>}
//                     <Input
//                         placeholder={ 'phone' }
//                         value={this.phone}
//                         onChange={(e)=>{this.setState({phone:e.target.value}) }}
//                     />
//                 </FormGroup>
//                 <FormGroup>
//                     { <Label>date</Label>}
//                     <Input
//                         placeholder={ 'date' }
//                         value={this.date}
//                         onChange={(e)=>{this.setState({date:e.target.value})}}
//                         type="date"
//                     />
//                 </FormGroup>
//                 <div className="form-row">
//                     <FormGroup className="col-6">
//                     { <Label>Password</Label>}
//                     <Input
//                         placeholder={ 'Password' }
//                         value={this.password}
//                         onChange={(e)=>this.setState({password:e.target.value})}
//                         type="password"
//                     />
//                     </FormGroup>
//                     <FormGroup className="col-6">
//                     { <Label>Confirm Password</Label>}
//                     <Input
//                         placeholder={ 'Confirm Password' }
//                         value={this.confirmPassword}
//                         onChange={(e)=>this.setState({password:e.target.value})}
//                         type="password"
//                     />
//                     </FormGroup>
//                 </div>
//
//                 <CustomInput
//                     id="customCheckTerms"
//                     label={
//                     <Fragment>
//                         I accept the <Link to="#!">terms</Link> and <Link to="#!">privacy policy</Link>
//                     </Fragment>
//                     }
//                     checked={this.isAccepted}
//                     onChange={(e) => this.setState({IsAccepted:e.target.checked})}
//                     type="checkbox"
//                 />
//                 <FormGroup>
//                     <Button color="primary" block className="mt-3" >
//                     Register
//                     </Button>
//                 </FormGroup>
//                 <Divider className="mt-4">or register with</Divider>
//                 <SocialAuthButtons />
//             </Form>
//
//
//
//
//
//         )
//     }
//
// }
