import React,{useState,useEffect} from "react";
import {Form,FormGroup,Label,Input,Row,CustomInput,Col,Button} from 'reactstrap';
//import { toast } from 'react-toastify';
import withRedirect from '../../hoc/withRedirect';
//import { arrayReducer } from '../../reducers/arrayReducer';
import {sendData} from "../../helpers/utils";
import {setItemToStore,getItemFromStore,sendDataLogin} from "../../helpers/utils";
import Loader from "../common/Loader";
import Divider from '../common/Divider';

//import { AuthContext } from '../../context/Context';
const LoginForm = ({ setRedirect, hasLabel, layout,setRedirectUrl }) =>{
 // const {setUpUser} = useContext(AuthContext);
  const [loading,setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password,setPassword] =  useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [trigger,setTrigger] = useState(false)
  const [appData,setAppData] = useState([])


  const handleSubmit = e =>{
               e.preventDefault()
              //  userLogin({"username":email,"password":password}).then((res) => {
              //     console.log("result data", res.data);
              // });
              const tenantResolver = {"username":email}
               sendData("signin","tenant/resolve",tenantResolver)
               .then((res)=>{
                 if(res.data.length >= 2){
                    setTrigger(true)
                    setAppData(res.data)
                 }
                 else{

                   handleLogin(res.data[0])
                 }



               })

  }

  const handleLogin = (data)=>{
    let tenant = data.tenantId
    // dispatch({type:'LOGIN_STATUS',user:email,token:res.data.access})
    //  toast.success("Logged in as ");
     const userLogin =  {username:email,password:password}
     sendDataLogin("signin","login",userLogin,tenant).then((res)=>{
         setItemToStore("tenantId",tenant)
         setItemToStore("token",res.data.access)
         setItemToStore("refresh",res.data.refresh)
         setRedirect(true);
         setRedirectUrl('/dashboard')
     })



  }

  useEffect(() => {
       setIsDisabled(!email || !password);
     }, [email,password]);


  const checkLogin= () =>{
    if(getItemFromStore('token')){
      setLoading(true)
      setRedirectUrl('/dashboard')
      return setRedirect(true);
    }
    else{
      setLoading(true)
      return setRedirect(false);
    }

  }
  useEffect(checkLogin,[]);

  return(
    <>
    {trigger?(<div className="row">
      {appData.map((data,idx)=>(
        <div className="col-6 mt-2" key={idx}>
          <button type="button" className="btn btn-outline-warning" onClick={()=>handleLogin(data)} >{data.tenantName}</button>
        </div>
        ))}

      </div>):(
      <>
     {loading === false?(<Loader/>):(
       <>
      <Divider className="mt-4">OR</Divider>
      <Form onSubmit={handleSubmit}>
        <FormGroup>

                  <Input
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type='text'
                  />
        </FormGroup>
        <FormGroup>
                  <Input
                     placeholder='Password'
                   value={password}
                   onChange={({ target }) => setPassword(target.value)}
                     type="password"
                  />
              </FormGroup>
              <Row className="justify-content-between align-items-center">
                 <Col xs="auto">
                 <CustomInput
                      id="customCheckRemember"
                      label="Remember me"
                      type="checkbox"
                    />

                 </Col>
              </Row>
                <FormGroup>
              <Button  block className="mt-3 bg-primary " disabled={isDisabled}>
                Log in
              </Button>
                  <Divider className="mt-4">OR</Divider>
                    Don't Have Account ?<a href="/register" className="ml-2 text-primaryTint" rel="noreferrer noopener">Create Account</a>
                </FormGroup>
            </Form>
          </>
        )}
      </>)}

    </>
    )

}
export default withRedirect(LoginForm);


// let tenant = res.data
// // dispatch({type:'LOGIN_STATUS',user:email,token:res.data.access})
// //  toast.success("Logged in as ");
//  sendDataLogin("signin","login",userLogin,res.data).then((res)=>{
//      setItemToStore("tenantId",tenant)
//      setItemToStore("token",res.data.access)
//      setItemToStore("refresh",res.data.refresh)
//      setRedirect(true);
//      setRedirectUrl('/dashboard')
//  })
