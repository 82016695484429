import React,{useReducer,useState,useEffect} from "react";
import {AuthContext} from '../../context/Context';
import { authReducer } from '../../reducers/authReducer';
import { getItemFromStore} from '../../helpers/utils';
import withRedirect from "../../hoc/withRedirect";
const AuthProvider = ({children,redirectUrl,setRedirectUrl,setRedirect})=>{

	// Reducer

	const [token,tokenDispatcher] = useReducer(authReducer,[],() => getItemFromStore('token'))
	// state
	const [user,setUser] = useState(null)
	//helpers
	const [leads,setLeadData] = useState([]);

	const tokenSetter = (tok)=> token === undefined?null:token;

	const pageRedirect =()=>{
		if(tokenSetter() === null){
			return true;
		}
		return false;
	}


	//life cycl

	useEffect(()=>{if(pageRedirect()){
		setRedirect(true)
		setRedirectUrl('/signin')}
	}
		// eslint-disable-next-line
	,[setRedirect,setRedirectUrl])

	
	const value = {
		token,
		tokenDispatcher,
		user,
		setUser,
		leads,
		setLeadData
	}

	return(
		<AuthContext.Provider value={value}>
			{children}
		</AuthContext.Provider>)

}


export default withRedirect(AuthProvider);
