import React, { Fragment } from 'react';
import withAuthSplit from '../../hoc/withAuthSplit';
import Flex from '../common/Flex';
import DomainForm from "./DomainForm";
const DomainAuth=()=>{
	return(
			<Fragment>
				<Flex align="center" justify="between">
        			<h5>Site Name</h5>
      			</Flex>
      			<DomainForm />

			</Fragment>

		)
}

export default withAuthSplit(DomainAuth);
