import React, { useState } from 'react';
import Container from 'reactstrap/es/Container';
import CardBody from 'reactstrap/es/CardBody';
import CardHeader from 'reactstrap/es/CardHeader';
import { Card, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Flex from '../components/common/Flex';

const withAuthSplit = OriginalComponent => {
  const UpdatedComponent = props => {
    // State


    return (
      <Container fluid>
        <Row className="min-vh-100 bg-100">
          <Col sm={10} md={6} className="px-sm-0 align-self-center mx-auto py-5">
            <Row noGutters className="justify-content-center">
              <Col lg={9} xl={8} className="col-xxl-6">
                <Card style={{width:"347px"}}>
                  <CardHeader className="bg-split-card-header  text-center p-2">
                    <Link
                      className="text-white text-sans-serif font-weight-extra-bold fs-4 z-index-1 position-relative"
                      to="/"
                    >

                      <img src="https://media.moxtain.com/icon/logo.png" alt="https://i.etsystatic.com/26375241/r/il/d2a025/3333550667/il_340x270.3333550667_67a2.jpg"   className="pt-4" width="160px"/>
                    </Link><br/>
                  </CardHeader>
                  <CardBody className="p-4">
                    <OriginalComponent  />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  };

  return UpdatedComponent;
};

export default withAuthSplit;
