import React from 'react';
import { Form, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SearchBox = ({place,change,blur}) => (
  <Form inline className="search-box">
    <Input type="search" placeholder={place} aria-label="Search" className="rounded-pill search-input"  onChange={change} onBlur={blur}/>
    <FontAwesomeIcon icon="search" className="position-absolute text-400 search-box-icon" />
  </Form>
);

export default SearchBox;
