import React,{useEffect, useState} from "react"
import jwt_decode from "jwt-decode";
import withRedirect from '../../hoc/withRedirect';
import {setItemToStore,sendDataLogin,sendData} from "../../helpers/utils";

const GoogleSigninButton =({ setRedirect, hasLabel, layout,setRedirectUrl })=> {
    const [gsiscriptloaded, setgsiscriptloaded] = useState(false)
    const [trigger,setTrigger] = useState(false)
    const [appData,setAppData] = useState([])
    const [credential,setCredential] = useState({})

    const handlegooglesignin = (res) => {
      let data = jwt_decode(res.credential)
      setCredential({clientId: res.clientId,credential: res.credential,select_by:res.select_by})
      let cre = {clientId: res.clientId,credential: res.credential,select_by:res.select_by}
      const tenantResolver = {"username":data.email,"appId":process.env.REACT_APP_ID}
       sendData("signin","tenant/resolve",tenantResolver)
       .then((res)=>{
          if(res.data.length >= 2){
              setTrigger(true)
              setAppData(res.data)
          }
          else{
            handleLoginSingle(res.data[0],cre)
          }

       }).catch((err)=>console.log(err.response.data))
    }

    const handleLogin = (data)=>{
      let tenant = data.tenantId
      sendDataLogin("signin","login/google",credential,tenant).then((res)=>{
          setItemToStore("tenantId",tenant)
          setItemToStore("token",res.data.access)
          setItemToStore("refresh",res.data.refresh)
          setRedirect(true);
          setRedirectUrl('/dashboard')
      })
      .catch((err)=>{
          console.log(err.response.data)
      })


    }

    const handleLoginSingle = (data,cre)=>{
      let tenant = data.tenantId
      sendDataLogin("signin","login/google",cre,tenant).then((res)=>{
          setItemToStore("tenantId",tenant)
          setItemToStore("token",res.data.access)
          setItemToStore("refresh",res.data.refresh)
          setRedirect(true);
          setRedirectUrl('/dashboard')
      })
      .catch((err)=>{
          console.log(err.response.data)
      })



    }



    /*google Login initialize */

    useEffect(() => {
        const initializeGsi = () => {
            setgsiscriptloaded(true)
            window.google.accounts.id.initialize({
                client_id:process.env.REACT_APP_GOOGLE_CLIENT_ID,
                callback: handlegooglesignin,
            })
        }

        const script = document.createElement("script")
        script.src = "https://accounts.google.com/gsi/client"
        script.onload = initializeGsi
        script.async = true
        script.id = "google-client-script"
        document.querySelector("body").appendChild(script)
        return () => {

            document.getElementById("google-client-script").remove()
        }
    },[handlegooglesignin])


    /* Handle Google Auto Login*/




    useEffect(() => {
      window.onload = function () {
          var refresh = localStorage.getItem("refresh")
          window.google.accounts.id.initialize({
              client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
              callback: (data) => handlegooglesignin(data),
          })
          if (refresh === null) {
              window.google.accounts.id.prompt()
          }
      }
  },[handlegooglesignin])


  /* Handle Google Auto Login End*/




    return (
      <>

      {trigger?(<>
        <div className="row">
        {appData.map((data,idx)=>(
          <div className="col-6 mt-2" key={idx}>
            <button type="button" className="btn btn-outline-warning" onClick={()=>handleLogin(data)} >{data.tenantName}</button>
          </div>
          ))}
          </div>

        </>):(
        <div className="g_id_signin"
           data-type="standard"
           data-theme="outline"
           data-text="signin_with"
           data-size="large"
           data-logo_alignment="left"
           data-width="290"
           />


      )}


      </>

    )
}

export default withRedirect(GoogleSigninButton);
