import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import LoginForm from '../NewLoginform';
import withAuthSplit from '../../../hoc/withAuthSplit';
import GoogleLoginButton from "../GoogleLogin"
// import bgImg from "../../../assets/img/generic/14.jpg"
//import bgImg from "../../../assets/img/generic/loginbg.jpg"


const Login = () => {


  return (
    <Fragment>
      <div className="px-auto">
       <GoogleLoginButton />
      </div>
      <LoginForm layout="split" hasLabel />
    </Fragment>
  );
};


export default withAuthSplit(Login);
